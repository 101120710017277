<template>
<div>
    <Loading v-if="serviceLoading" />
    <div v-else>
        <div 
            v-if="service.replies.length > 0"
            class="policy-service" 
            :class="[
                {'animate__animated animate__zoomOutLeft': replyIsDeleting === reversedReplies[0].id,},
                { deleting: isReplyDeleted },
            ]"
        >
            <template>
                <div class="policy-service__row">
                    <div class="policy-service__data sender-data">
                        <div class="sender">
                            <p class="sender-name">{{ reversedReplies[0].user.first_name }} {{ reversedReplies[0].user.last_name }}</p>
                            <p>{{ formatDate(reversedReplies[0].created_at)}}</p>
                        </div>

                        <div class="contact">
                            <a v-if="reversedReplies[0].user.phone" :href="'tel:'+reversedReplies[0].user.phone">
                                <div class="icon icon__phone--active"></div>
                            </a>
                            <div v-else class="icon icon__phone"></div>

                            <a v-if="reversedReplies[0].user.email" :href="'mailto:'+reversedReplies[0].user.email">
                                <div class="icon icon__mail--active"></div>
                            </a>
                            <div v-else class="icon icon__mail"></div>
                        </div>
                    </div>
                    <div class="policy-service__data message-data">
                        <p>{{ reversedReplies[0].description }}</p>
                    </div>
                    <div class="policy-service__data deadline-data medium-data">
                        <p class="deadline">
                            <span v-if="reversedReplies[0].deadline">
                                {{ formatDate(reversedReplies[0].deadline) }}
                            </span>
                            <span v-else>{{ $t(`dashboard.statistics.${"noDeadline"}`) }}</span>
                        </p>
                        <p>{{reversedReplies[0].agent.first_name}} {{reversedReplies[0].agent.last_name}}</p>
                    </div>

                    <div class="policy-service__data status-data medium-data">
                        <div class="status-indicator" :class="`status-indicator--${reversedReplies[0].status.id}`"></div>
                            
                        <p>{{ reversedReplies[0].status.name_de}}</p>
                    </div>

                    <div class="policy-service__data tools-data  "> 
                        <div 
                            class="icon icon__add tool-icon" 
                            @click="showAddReplyPendencyModal(policy ? policy.id : null, service.id, agent, service.user);"
                        >
                        </div>
                        
                        <div
                            v-if="service.files.length"
                            class="icon icon__pdf--active tool-icon" 
                            @click="openPendencyFilesModal()"
                        >
                        </div>

                        <div v-else class="icon icon__pdf tool-icon"></div>

                        <div 
                            class="icon icon__edit tool-icon" 
                            @click="showEditReplyModal(reversedReplies[0], service.policy ? service.policy.id : null, service.agent, service.user)"
                        ></div>

                        <div
                            class="icon icon__delete tool-icon"
                            :class="{ 'icon__delete--active': activeReplyId === reversedReplies[0].id }"
                            tabindex="-1"
                            @focus="activeReplyId = reversedReplies[0].id"
                            @focusout="activeReplyId = null"
                        >
                            <transition name="fade">
                                <div class="delete" v-if="activeReplyId === reversedReplies[0].id">
                                    <div class="delete__text">
                                        Are you sure you want to delete this Reply?
                                    </div>
                                    <div class="delete__buttons">
                                        <div
                                        class="button button--confirm"
                                        @click="deletePendencyReply(reversedReplies[0].id)"
                                        ></div>
                                        <div
                                        class="button button--cancel"
                                        @click="closeReplyDeletePopup"
                                        ></div>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>

                    <div class="policy-service__data arrow-data">
                        <div 
                            class="icon icon__chevron-down-green" 
                            v-if="reversedReplies.length > 0" 
                            @click="toggleReplies" :class="showReplies ? 'rotate-chevron' : ''"
                        >
                        </div>
                        <div class="icon icon__chevron-down-gray" v-else style="cursor: initial;"></div>
                    </div>
                </div>
            </template>
        </div>

        <div 
            v-else
            class="policy-service" 
            :class="[
                {'animate__animated animate__zoomOutLeft': serviceIsDeleting === service.id,},
                { deleting: isServiceDeleted },
            ]"
        >
            <template>
                <div class="policy-service__row">
                    <div class="policy-service__data sender-data">
                        <div class="sender">
                            <p class="sender-name">{{ service.user.first_name }} {{ service.user.last_name }}</p>
                            <p>{{ formatDate(created)}}</p>
                        </div>

                        <div class="contact">
                            <a v-if="service.user.phone" :href="'tel:'+service.user.phone">
                                <div class="icon icon__phone--active"></div>
                            </a>
                            <div v-else class="icon icon__phone"></div>

                            <a v-if="service.user.email" :href="'mailto:'+service.user.email">
                                <div class="icon icon__mail--active"></div>
                            </a>
                            <div v-else class="icon icon__mail"></div>
                        </div>
                    </div>

                    <div class="policy-service__data message-data">
                        <p>{{ description }}</p>
                    </div>
                    <div class="policy-service__data deadline-data medium-data">
                        <p class="deadline">  
                            <span v-if="deadline">
                                {{ formatDate(deadline) }}
                            </span>
                            <span v-else>{{ $t(`dashboard.statistics.${"noDeadline"}`) }}</span>
                        </p>
                        <p>{{agent.first_name}} {{agent.last_name}}</p>
                    </div>
                    <div class="policy-service__data status-data medium-data">
                        <div class="status-indicator" :class="`status-indicator--${status.id}`"> </div>
                        <p>{{ status.name_de}}</p>
                    </div>

                    <div class="policy-service__data tools-data  ">
                        <div 
                            class="icon icon__add tool-icon" 
                            @click="showAddReplyPendencyModal(policy ? policy.id : null, service.id, agent, service.user);"
                        >
                        </div>
                    
                        <div
                            v-if="service.files.length"
                            class="icon icon__pdf--active tool-icon" 
                            @click="openPendencyFilesModal()"
                        >
                        </div>

                        <div v-else class="icon icon__pdf tool-icon"></div>

                        <div class="icon icon__edit tool-icon" @click="showEditPendencyModal(service)"></div>
                        
                        <div class="icon icon__delete tool-icon" 
                            :class="{ 'icon__delete--active': showDeletePendency }" 
                            tabindex="-1" 
                            @focus="showDeletePendency = true" 
                            @focusout="showDeletePendency = false" ref="delete"
                        > 
                            <transition name="fade">
                                <div class="delete" v-if="showDeletePendency">
                                    <div class="delete__text">
                                        Are you sure you want to delete this Pendency?
                                    </div>
                                    <div class="delete__buttons">
                                        <div class="button button--confirm" @click="deletePendency()"></div>
                                        <div class="button button--cancel" @click="closeDeletePopup()"></div>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>

                    <div class="policy-service__data arrow-data">
                        <div 
                            class="icon icon__chevron-down-green" 
                            v-if="replies.length > 0" 
                            @click="toggleReplies" :class="showReplies ? 'rotate-chevron' : ''"
                        >
                        </div>
                        <div class="icon icon__chevron-down-gray" v-else style="cursor: initial;"></div>
                    </div>
                </div> 
            </template> 
        </div>
    </div>

    <div v-if="showReplies">
        <div 
            v-for=" reply  in filteredReversedReplies" 
            :key="reply.id"  
            class="policy-service" 
            :class="[
                {'animate__animated animate__zoomOutLeft': replyIsDeleting === reply.id,},
                { deleting: isReplyDeleted },
            ]"
        > 
            <template  >
                <div class="policy-service__row">
                    <div class="policy-service__data sender-data">
                        <div class="sender">
                            <p class="sender-name">{{reply.user.first_name}} {{reply.user.last_name}} </p>
                            <p>{{ formatDate(reply.created_at)}}</p>
                        </div>

                        <div class="contact">
                            <a v-if="reply.user.phone" :href="'tel:'+reply.user.phone">
                                <div class="icon icon__phone--active"></div>
                            </a>
                            <div v-else class="icon icon__phone"></div>

                            <a v-if="reply.user.email" :href="'mailto:'+reply.user.email">
                                <div class="icon icon__mail--active"></div>
                            </a>
                            <div v-else class="icon icon__mail"></div>
                        </div>
                    </div>

                    <div class="policy-service__data message-data">
                        <p>{{ reply.description }}</p>
                    </div>
                    <div class="policy-service__data deadline-data medium-data"> 
                        <p class="deadline">
                            <span v-if="reply.deadline">{{ formatDate(reply.deadline) }}</span>
                            <span v-else>{{ $t(`dashboard.statistics.${"noDeadline"}`) }}</span>
                        </p>
                        <p>{{ reply.agent.first_name }} {{ reply.agent.last_name }}</p>
                    </div>
                    <div class="policy-service__data status-data medium-data">
                        <div class="status-indicator" :class="`status-indicator--${reply.status.id}`"> </div>
                        <p>{{reply.status.name_de}}</p>
                    </div>

                    <div class="policy-service__data tools-data  ">
                        <div class="icon  tool-icon"></div>
                        <div class="icon  tool-icon"></div>
                        <div class="icon icon__edit tool-icon" @click="showEditReplyModal(reply, service.policy ? service.policy.id : null, service.agent, service.user)"></div>
                        <div
                          class="icon icon__delete tool-icon"
                          :class="{ 'icon__delete--active': activeReplyId === reply.id }"
                          tabindex="-1"
                          @focus="activeReplyId = reply.id"
                          @focusout="activeReplyId = null"
                        >
                          <transition name="fade">
                            <div class="delete" v-if="activeReplyId === reply.id">
                              <div class="delete__text">
                                Are you sure you want to delete this Reply?
                              </div>
                              <div class="delete__buttons">
                                <div
                                  class="button button--confirm"
                                  @click="deletePendencyReply(reply.id)"
                                ></div>
                                <div
                                  class="button button--cancel"
                                  @click="closeReplyDeletePopup"
                                ></div>
                              </div>
                            </div>
                          </transition>
                        </div>
                    </div>

                    <div class="policy-service__data arrow-data"></div>
                </div>
            </template>
        </div>

        <div  
            class="policy-service" 
            :class="[
                {'animate__animated animate__zoomOutLeft': serviceIsDeleting === service.id,},
                { deleting: isServiceDeleted },
            ]"
        >
            <template>
                <div class="policy-service__row">
                    <div class="policy-service__data sender-data">
                        <div class="sender">
                            <p class="sender-name">{{ service.user.first_name }} {{ service.user.last_name }}</p>
                            <p>{{ formatDate(created)}}</p>
                        </div>

                        <div class="contact">
                            <a v-if="service.user.phone" :href="'tel:'+service.user.phone">
                                <div class="icon icon__phone--active"></div>
                            </a>
                            <div v-else class="icon icon__phone"></div>

                            <a v-if="service.user.email" :href="'mailto:'+service.user.email">
                                <div class="icon icon__mail--active"></div>
                            </a>
                            <div v-else class="icon icon__mail"></div>
                        </div>
                    </div>

                    <div class="policy-service__data message-data">
                        <p>{{ description }}</p>
                    </div>
                    <div class="policy-service__data deadline-data medium-data">
                        <p class="deadline">
                            <span v-if="deadline">{{ formatDate(deadline) }}</span>
                            <span v-else>{{ $t(`dashboard.statistics.${"noDeadline"}`) }}</span>
                        </p>
                        <p>{{agent.first_name}} {{agent.last_name}}</p>
                    </div>
                    <div class="policy-service__data status-data medium-data">
                        <div class="status-indicator" :class="`status-indicator--1`"> </div>
                        <p>Zu Erledigen</p>
                    </div>

                    <div class="policy-service__data tools-data  ">
                        <div class="icon  tool-icon"></div> 
                        <div class="icon  tool-icon"></div> 

                        <div class="icon icon__edit tool-icon" @click="showEditPendencyModal(service)"></div>
                        
                        <div class="icon icon__delete tool-icon" 
                            :class="{ 'icon__delete--active': showDeletePendency }" 
                            tabindex="-1" 
                            @focus="showDeletePendency = true" 
                            @focusout="showDeletePendency = false" ref="delete"
                        > 
                            <transition name="fade">
                                <div class="delete" v-if="showDeletePendency">
                                    <div class="delete__text">
                                        Are you sure you want to delete this Pendency?
                                    </div>
                                    <div class="delete__buttons">
                                        <div class="button button--confirm" @click="deletePendency()"></div>
                                        <div class="button button--cancel" @click="closeDeletePopup()"></div>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>

                    <div class="policy-service__data arrow-data"></div>
                </div> 
            </template> 
        </div>
    </div>
 
</div>
</template>

<script>
import {
    mapGetters
} from "vuex";
// import PopupEditer from "@/partials/PopupEditer";
import Loading from "@/partials/Loading";
 
export default {
    name: "PolicyService",

    components: {
        //PopupEditer,
        Loading,
    },

    props: { 
        service: {
            required: true,
            type: Object,
        },
        replies: {
            required: true,
            type: Array,
        },
        policy: {
            //required: true,
            type: Object,
        },
        agent: {
            required: true,
            type: Object,
        },
        created: {
            required: true,
            type: String,
        },
        description: {
            required: true,
            type: String,
        },
        deadline: {
            //required: true,
            type: String,
        },
        receiver: {
            required: true,
            type: Object,
        },
        status: {
            required: true,
            type: Object,
        },
    },

    data() {
        return {
            showDeletePendency: false,
            showDeleteReply: false,

             activeReplyId: null,

            

            showReplies: false,

              
            serviceLoading: false,
            serviceIsDeleting: "",
            replyIsDeleting: "",
            isServiceDeleted: false,
            isReplyDeleted: false,  
        };
    },

    computed: {
        ...mapGetters(["policyForms", "language", 'loggedUser']),

        reversedReplies() {
            return [...this.service.replies].reverse();
        },

        filteredReversedReplies(){
            return this.reversedReplies.slice(1);
        },

 
    },

    methods: {
        showEditPendencyModal(service){ 
            this.$store.commit("setShowAddPendencyModal", true); 
            this.$store.commit("setIsPendecyEditing", true); 
            this.$store.commit("setPendencyEditData", service);
        },


        showEditReplyModal(reply, policyId, agent){    
            this.$store.commit("setShowAddPendencyModal", true); 
            this.$store.commit("setIsReplyEditing", true);
            this.$store.commit("setPendencyForReply", true); 
            this.$store.commit("setReplyEditData", {reply, policyId});
            this.$store.commit("setPendencyReplyAgentData", agent);
        },
  


        toggleReplies() {
            this.showReplies = !this.showReplies;
            this.$emit('toggle-replies');
        },

        showAddReplyPendencyModal(policyId, serviceId, agent, user) {  
            if(this.loggedUser && user){
                
                if(this.loggedUser.id == user.id){
                    this.$store.commit("setIsUserReplyingToPendency", true);
                }else{
                    this.$store.commit("setIsUserReplyingToPendency", false);
                }
            }else{
                this.$store.commit("setIsUserReplyingToPendency", false);
            }

            this.$store.commit("setPendencyForReply", true);
            this.$store.commit("setShowAddPendencyModal", true);
            this.$store.commit("setPendencyReplyData", {
              policyId: policyId,
              serviceId,
              agent
            });

        },

        formatDate(dateString) {
            if (!dateString) return '';

            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();

            return `${day}.${month}.${year}`;
        },

        async deletePendency() {
            const pendencyId = this.service.id;
            const customerId = this.$route.params.id;

            this.serviceIsDeleting = this.service.id;

            this.closeDeletePopup();
            await this.$store.dispatch("deletePendencies", {
                pendencyId,
                customerId
            });
            this.isServiceDeleted = true;
        },

        closeDeletePopup() {
            this.$refs.delete.blur();
            this.showDeletePendency = false;
        },

        async deletePendencyReply(id) {
            const replyId = id;
            const customerId = this.$route.params.id;
            this.replyIsDeleting = id;
            this.closeReplyDeletePopup();
            await this.$store.dispatch("deleteReplyPendencies", {
                replyId,
                customerId
            });
            this.isReplyDeleted = true;

            this.toggleReplies = false;
        },
 
        closeReplyDeletePopup() {
            this.$refs.delete.blur();
            this.activeReplyId = null;  
        }, 

        openPendencyFilesModal() { 
            this.$store.commit("setShowFilesEditModal", true);
            this.$store.commit("setFilesToEditId", this.service.id);
            this.$store.commit("setFilesToEdit", this.service.files); 
            this.$store.commit("setFilesEditMode", 'Pendencies'); 
        }, 
    },
};
</script>

<style lang="scss" scoped>
.policy-service__data {
    flex: initial !important;
}

.replies-row {
    margin-top: 14px;
}

.sender-data {
    /* 
  width: 200px !important; */
    justify-content: space-between;
}

.sender-data,
.sender,
.contact {

    display: flex;

}

.sender {
    flex-direction: column;
    justify-content: center;
    color: var(--text-9);;
}

.sender-name,
.deadline {
    margin-bottom: 5px;
}

.contact {
    margin-right: 20px;
    gap: 15px;
}

.message-data p {
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    margin: 0% !important;
    margin-left: 0;
    height: 90%;
    width: 100%;
    font-size: 14px;
}

.deadline-data {
    flex-direction: column;
    color: var(--text-9);
}

.status-indicator {
    width: 16px;
    height: 16px;

    border-radius: 10px;
}

.status-indicator--1 {
    background-color: #ff3333;
}

.status-indicator--2 {
    background-color: #FF9E0F;
}

.status-indicator--3 {
    background-color: #22db8e;
}

.status-data {
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
}

.tools-data {
    justify-content: space-around;
}

.tool-icon {
    border: 1px solid transparent;
    height: 100%;
    width: 25%;
    border-left-color: #7F8FA426;
}

.icon__delete {
    border-right-color: #7F8FA426;
}

.sender-data {
    width: 22%;
}

.message-data {
    width: 35%;
}

.medium-data {
    width: 14%;
    justify-content: center;
    text-align: center;
}

.tools-data {
    width: 12%;
    justify-content: center;
    text-align: center;
}

.arrow-data {
    width: 3%;
    justify-content: center;
}

.rotate-chevron {
    transform: rotate(180deg);
}




[data-theme="dark"] {
 
    .message-data p{
        background-color: #46505B; 
    }
}
</style>
