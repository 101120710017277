<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--transaction"></div>
      <div class="modal__close" @click="closeModal()"></div>
      <div class="modal__title">{{ $t(`addTransaction.${"bonusTitle"}`) }}</div>

      <form class="form" @submit.prevent="createTransaction()">
        <div class="add__form">
          <transition name="fade" mode="out-in">
            <div class="add__content">
              <div class="form__field">
                <VSelect
                  class="select select--default"
                  :class="{ 'select--error': userHasError }"
                  :options="agents"
                  :reduce="(agent) => agent.id"
                  placeholder="Agent"
                  :getOptionLabel="
                    (agent) => `${agent.first_name} ${agent.last_name}`
                  "
                  v-model="userId"
                />
                <div class="form__error" v-if="userHasError">
                  {{ $t(`addTransaction.${"agentErrorMsg"}`) }}
                </div>
              </div>
              <div class="form__field">
                <VSelect
                  class="select select--default"
                  :class="{ 'select--error': transactionHasError }"
                  :options="transactionTypes"
                  :reduce="(transaction) => transaction.id" 
                  :placeholder="$t('addTransaction.transactionType')"
                  label="name"
                  v-model="transactionTypeId"
                />
                <div class="form__error" v-if="transactionHasError">
                  {{ $t(`addTransaction.${"transactionErrorMsg"}`) }}
                </div>
              </div>
              <div class="form__field">
                <div class="modal__report-price">
                  <div class="modal__report-prefix">{{ prefixSign }}</div>
                  <input
                    type="text"
                    class="input input--default"
                    :class="{ 'input--error': amountHasError }"
                    v-model="amount"
                  />
                  <div class="modal__report-label">.-CHF</div>
                  <div class="modal__report-popup">
                    <div class="modal__report-icon"></div>
                    <div class="modal__report-text">
                      Stornokonto auszahlungen müssen mit (-) deklariert werden
                    </div>
                  </div>
                </div>
                <div class="form__error" v-if="amountHasError">
                  {{ amountErrorPlaceholder }}
                </div>
              </div>
              <div class="form__field">
                <textarea
                  class="input input--default"
                  :class="{ 'input--error': descriptionHasError }"
                  id=""
                  cols="30"
                  rows="10" 
                  :placeholder="$t('addTransaction.desctiption')"
                  v-model="description"
                ></textarea>
                <div class="form__error" v-if="descriptionHasError">
                  {{ $t(`addTransaction.${"desctiptionErrorMsg"}`) }}
                </div>
              </div>
              <div class="form__field">
                <div class="input input--default modal__report-time">
                  {{ `${formatDisplayDate(moment())} / ${displayTime}` }}
                </div>
              </div>

              <div
                v-if="isRequesting"
                class="button modal__button modal__button--submit button--default button--loading"
              ></div>
              <button
                type="submit"
                v-else
                @click="createTransaction()"
                class="button modal__button modal__button--submit button--default"
              >
              {{ $t(`addTransaction.${"saveBtn"}`) }}
              </button>
            </div>
          </transition>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDisplayDate } from "@/helpers/dateFormatters";
import moment from "moment";
export default {
  name: "AddTransactionModal",

  data() {
    return {
      moment,
      formatDisplayDate,
      displayTime: moment().format("H:mm"),
      isRequesting: false,
      userId: "",
      description: "",
      transactionTypeId: "",
      amount: 0,

      prefixSign: "",

      userHasError: false,
      transactionHasError: false,
      amountHasError: false,
      descriptionHasError: false,

      amountErrorPlaceholder: "",
    };
  },

  watch: {
    userId() {
      this.validateAgent();
    },

    transactionTypeId(typeId) {
      this.validateTransaction();

      if (typeId) {
        const isDeposit = this.transactionTypes.find(
          (transaction) => transaction.id === typeId
        ).is_deposit;

        if (isDeposit) {
          this.prefixSign = "-";
        } else {
          this.prefixSign = "+";
        }
      }
    },

    amount() {
      this.validateAmount();
    },

    description() {
      this.validateDescription();
    },
  },

  computed: {
    ...mapGetters(["reportForms"]),

    transactionTypes() {
      return this.reportForms["transaction_types"];
    },

    agents() {
      return this.reportForms.agents;
    },
  },

  mounted() {
    if (this.$route.query.userId) {
      this.userId = Number(this.$route.query.userId);
    }
    setInterval(this.checkTime, 1000);
  },

  destroyed() {
    clearInterval(this.checkTime);
  },

  methods: {
    closeModal() {
      this.$store.commit("setShowAddTransactionModal", false);
    },

    checkTime() {
      if (this.displayTime !== moment().format("H:mm")) {
        this.displayTime = this.moment().format("H:mm");
      }
    },

    validateAgent() {
      if (!this.userId) {
        this.userHasError = true;
      } else {
        this.userHasError = false;
      }
    },

    validateTransaction() {
      if (!this.transactionTypeId) {
        this.transactionHasError = true;
      } else {
        this.transactionHasError = false;
      }
    },

    validateAmount() {
      if (this.amount) {
        if (this.amount > 0) {
          this.amountHasError = false;
          this.amountErrorPlaceholder = "";
        } else {
          if(this.transactionTypeId > 2)
          {
            this.amountHasError = true;
            this.amountErrorPlaceholder = "Invalid amount.";
          }else  {
            this.amountHasError = false;
            this.amountErrorPlaceholder = "";
          }

        }
      } else {
        this.amountHasError = true;
        this.amountErrorPlaceholder = "Please enter an amount.";
      }
    },

    validateDescription() {
      if (!this.description) {
        this.descriptionHasError = true;
      } else {
        this.descriptionHasError = false;
      }
    },

    async createTransaction() {
      this.isRequesting = true;
      this.validateAgent();
      this.validateTransaction();
      this.validateAmount();
      this.validateDescription();
      if (
        !this.userHasError &&
        !this.transactionHasError &&
        !this.amountHasError &&
        !this.descriptionHasError
      ) {
        try {
          await this.$store.dispatch("createTransaction", {
            user_id: this.userId,
            description: this.description,
            transaction_type_id: this.transactionTypeId,
            amount: this.amount,
          });
          if (
            Number(this.$route.query.userId) &&
            (this.$route.query.paidStatus === "true" ||
              this.$route.query.paidStatus === "false")
          ) {
            await this.$store.dispatch("getReports");
          }
          this.closeModal();
        } catch (err) {
          console.error(err);
        }
      }
      this.isRequesting = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  margin-top: 70px !important;
  height: calc(100% - 70px - 18px) !important;
}

.add {
  &__form {
    width: initial !important;
    padding: 0 30%;
    overflow: auto;
  }

  &__content {
    overflow: initial !important;
  }
}
.modal {
  &__title {
    font-size: var(--medium);
    color: var(--text-2);
    margin-left: 74px;
  }

  textarea {
    max-width: 100%;
    min-height: 100px;
    padding-top: 10px;
  }

  &__report {
    &-price {
      position: relative;
      .input {
        text-align: right;
        padding-right: 51%;
      }
    }

    &-price .input:hover ~ &-popup {
      opacity: 1;
    }

    &-label {
      left: 50%;
      pointer-events: none;
    }

    &-time {
      display: flex;
      align-items: center;
    }

    &-prefix,
    &-label {
      color: var(--text-8) !important;
      top: 50%;
      transform: translateY(-50%);
    }

    &-prefix,
    &-popup,
    &-label {
      position: absolute;
    }

    &-prefix {
      left: 15px;
    }

    &-popup {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 246px;
      height: 100%;
      padding: 5px 10px;
      box-shadow: 0px 3px 6px var(--shadow-3);
      top: 0;
      right: -100%;
      margin-right: 25px;
      border-radius: var(--radius-1);
      opacity: 0;
      pointer-events: none;
    }

    &-icon {
      min-height: 18px;
      min-width: 18px;
      background: url("../assets/icons/default/info_icon.svg") no-repeat center;
      background-size: cover;
    }

    &-text {
      color: var(--text-2);
      font-size: var(--x-smaller);
      padding-left: 5px;
    }
  }
}
</style>
