<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--setting"></div>
      <div class="modal__close" @click="closeModal()"></div>

      <div class="form">
        <div class="navigators">
          <div class="navigator navigator--active">
            <div class="navigator__bullet"></div>
            <div class="navigator__title">{{ $t(`addPermissionModal.${"title"}`) }}</div>
          </div>
        </div>
        <div class="add__form">
          <transition name="fade" mode="out-in">
            <div class="add__content">
              <div class="form__field">
                <input
                  type="text"
                  class="input input--default"
                  :class="{ 'input--error': permissionNameHasError }"
                  :placeholder="$t(`addPermissionModal.${'permissionPlaceholder'}`)" 
                  v-model="permissionName"
                />
                <div class="form__error" v-if="permissionNameHasError">
                  {{ $t(`addPermissionModal.${"permissionErrorMsg"}`) }}
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div
        v-if="isRequesting"
        class="modal__button modal__button--submit button button--default button--loading"
      ></div>
      <button
        v-else
        class="modal__button modal__button--submit button button--default"
        @click="addPermission()"
      >
        <span>{{ $t(`addPermissionModal.${"saveBtn"}`) }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddPermissionModal",

  data() {
    return {
      permissionName: "",
      permissionNameHasError: false,
      isRequesting: false,
    };
  },

  watch: {
    permissionName(value) {
      if (value) {
        this.permissionNameHasError = false;
      } else {
        this.permissionNameHasError = true;
      }
    },
  },

  methods: {
    closeModal() {
      this.$store.commit("setShowAddPermissionModal", false);
    },

    async addPermission() {
      this.isRequesting = true;
      if (this.permissionName) {
        await this.$store.dispatch("createPermission", this.permissionName);
        await this.$store.dispatch("getPermissions");
        this.closeModal();
        this.isRequesting = false;
      } else {
        this.permissionNameHasError = true;
        this.isRequesting = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.form__field {
  margin-top: 160px;
}

.modal__button {
  left: 50%;
  transform: translateX(-50%);
  bottom: 200px;
}
</style>
