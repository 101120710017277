import api from "@/api";

export default{
    state:{ 
        dashboardSales: {},

        dashboardInternal:{},
    },
    getters: { 
        dashboardSales: (state) => state.dashboardSales,

        dashboardInternal: (state) => state.dashboardInternal,
    },
    mutations: { 
        setDashboardSales: (state, data) => {
            state.dashboardSales = data;
        },

        setDashboardInternal: (state, data) => {
            state.dashboardInternal = data;
        },
    },
    actions:{
        getSalesDashboard: async ({commit}) => {
            try{
                const response = await api({ requiresAuth: true }).get(
                    "/v1/sales-dashboard"
                );

                const data = response;
                commit("setDashboardSales", data.data);
                return data;
            }catch (err) {
                console.log(err);
                throw err;
            }
        },

        getInternalDashboard: async ({commit}) => {
            try{
                const response = await api({ requiresAuth: true }).get(
                    "/v1/internal-dashboard"
                );

                const data = response;
                commit("setDashboardInternal", data.data);
                return data;
            }catch (err) {
                console.log(err);
                throw err;
            }
        },
    }
}