import api from "@/api";

export default {
    state: {
        pendencies: [],
        pendenciesToEdit: {},
        pendenciesFiles: [],
        pendencyFilesId: null,

        fromPolicyId: null,
        fromPolicyAgentId: null,
    },
    getters: {
        pendencies: (state) => state.pendencies,
        pendenciesToEdit: (state) => state.pendenciesToEdit,

        pendenciesFiles: (state) => state.pendenciesFiles,

        pendencyFilesId: (state) => state.pendencyFilesId,

        fromPolicyId: (state) => state.fromPolicyId,
        fromPolicyAgentId: (state) => state.fromPolicyAgentId,
    },
    mutations: {
        setPendencies: (state, data) => {
            state.pendencies = data;
        },

        setPendenciesToEdit: (state, data) => {
            state.pendenciesToEdit = data;
        },

        setPendenciesFiles: (state, data) => {
            state.pendenciesFiles = data;
        },

        setPendencyFilesId: (state, data) => {
            state.pendencyFilesId = data;
        },

        setFromPolicyId: (state, data) => {
            state.fromPolicyId = data;
        },

        setFromPolicyAgentId: (state, data) => {
            state.fromPolicyAgentId = data;
        },
    },
    actions:{
        getPendencies: async ({commit}, id) => {
            try{
                const response = await api({requiresAuth: true}).get(
                    `v1/customer-pendencies/${id}`
                );
                const {data} = response.data;
                commit("setPendencies", data);
                return data; 
            } catch (err) {
                console.log(err);
                throw err; 
            }
        },


        createPendencies: async (
            { dispatch }, 
            { policy_id, agent_id, customer_id, status_id, deadline, description }
          ) => {
            try {
              const response = await api({ requiresAuth: true }).post("v1/pendencies", {
                policy_id,
                agent_id,
                customer_id,
                status_id,
                deadline,
                description,
              }); 

              const newData = response.data.data;
      
              const newPendencyId = newData.id;
  
              await dispatch("getPendencies", customer_id);
              
              return newPendencyId;
            } catch (err) {
              console.error("Error creating pendency:", err);
              throw err;
            }
        },

        replyPendencies: async (
            { dispatch, commit }, 
            {pendency_id, policy_id, agent_id, customer_id, status_id, deadline, description}
        ) => {
            try {
                await api({ requiresAuth: true }).post("v1/pendency-replies", {
                    pendency_id,
                    policy_id,
                    agent_id,
                    customer_id,
                    status_id,
                    deadline,
                    description,
                }); 
                await commit("setPendencies", []);
                dispatch("getPendencies", customer_id);
            } catch (err) {
                console.log(err);
                throw err;
            }
        },


        updateReplyPendencies: async (
            { dispatch }, 
            {id, pendency_id, policy_id, agent_id, customer_id, status_id, deadline, description}
        ) => {
            try {
                await api({ requiresAuth: true }).patch(`v1/pendency-replies/${id}`, {
                    pendency_id,
                    policy_id,
                    agent_id,
                    customer_id,
                    status_id,
                    deadline,
                    description,
                }); 
                dispatch("getPendencies", customer_id);
            } catch (err) {
                console.log(err);
                throw err;
            }
        },

        updatePendencies: async (
            { dispatch }, 
            { id, policy_id, agent_id, customer_id, status_id, deadline, description }
        ) => {

             
            try {
                await api({ requiresAuth: true }).patch(`v1/pendencies/${id}`, {
                    policy_id,
                    agent_id,
                    customer_id,
                    status_id,
                    deadline,
                    description,
                });
                dispatch("getPendencies", customer_id);
            } catch (err) {
                console.log(err);
                throw err;
            }
        },

        deletePendencies: async ({ dispatch }, {pendencyId, customerId}) => {
            try {
                await api({ requiresAuth: true }).delete(`v1/pendencies/${pendencyId}`); 
                await dispatch("getPendencies", customerId);
            } catch (err) {
                console.log(err);
                throw err;
            }
        },

        deleteReplyPendencies: async ({ dispatch,  commit}, {replyId, customerId}) => {
            try {
                await api({ requiresAuth: true }).delete(`v1/pendency-replies/${replyId}`); 
                
                await commit("setPendencies", []);
                await dispatch("getPendencies", customerId);
            } catch (err) {
                console.log(err);
                throw err;
            }
        },
        updatePendencyStatus: async (_, { pendencyId, pendencyStatusId }) => {
            try {
             const response =  await api({ requiresAuth: true }).patch(
                    `/v1/pendencies/${pendencyId}/statuses`,
                    {
                        pendency_status_id: pendencyStatusId,
                    }
                );
                const {data} = response.data;

                return data;
            } catch (err) {
                console.error(err);
                throw err;
            }
        },

        addPendencyFile: async (_, { pendencyId, files }) => {
            const formData = new FormData();
            files.forEach((file) => {
              formData.append("pendency_files[]", file);
            });
            try {
              await api({ requiresAuth: true }).post(
                `/v1/pendencies/${pendencyId}/files`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              ); 
            } catch (err) {
              console.error(err);
              throw err;
            }
        },
 
        fetchPendencyFiles: async ({ commit }, pendencyId) => { 
            try {
              const response = await api({ requiresAuth: true }).get(
                `/v1/pendencies/${pendencyId}`
              );
              const { data } = response.data;  
              commit("setFilesToEdit", data.files); 
              return data;
            } catch (err) {
              console.error("Error fetching note files:", err);
              throw err;
            }
        },
    }
}


 