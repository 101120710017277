<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel modal__panel--search-potential">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--potential"></div>
      <div
        v-if="!showSearch"
        class="modal__back"
        @click="showSearch = true"
      ></div>
      <div
        class="modal__close"
        @click="$store.commit('setShowSearchPotentialCustomerModal', false)"
      ></div>

      <div class="form">
        <div class="navigators">
          <div class="navigator navigator--active">
            <div class="navigator__bullet"></div>
            <div class="navigator__title">{{ $t(`searchPotentialCustomers.${"title"}`) }}</div>
          </div>
        </div>
        <div class="add__form">
          <transition name="fade" mode="out-in">
            <Loading v-if="formLoading" />
            <div v-else class="add__content">
              <transition name="fade" mode="out-in">
                <div v-if="showSearch" class="form__field" key="0">
                  <div class="form__label">{{ $t(`searchPotentialCustomers.${"phoneLabel"}`) }}</div>
                  <input
                    type="text"
                    class="input input--default"
                    :class="{ 'input--error': phoneKeywordsHasError }"
                    v-model="phoneKeywords"
                  />
                  <div class="form__error" v-if="phoneKeywordsHasError">
                    {{ $t(`searchPotentialCustomers.${"phoneError"}`) }}
                  </div>
                </div>

                <div
                  v-else
                  class="contact"
                  :class="{ 'contact--exists': customerExists }"
                  key="1"
                  @click="customerExists ? openPotentialPolicy() : null"
                >
                  <div class="contact__thumbnail">
                    <img v-if="photo" :src="photo" alt="" />
                  </div>

                  <div class="contact__info">
                    <div class="contact__name">
                      <span v-if="customerExists">{{
                        parseCustomerName(potentialCustomerContact)
                      }}</span>
                      <span v-else>{{ $t(`searchPotentialCustomers.${"noResultsMsg"}`) }}</span>
                    </div>
                    <div class="contact__address">
                      <span v-if="customerExists">{{ street }}</span>
                      <div
                        v-else
                        class="contact__empty contact__empty--large"
                      ></div>
                    </div>
                    <div class="contact__city">
                      <span v-if="customerExists">
                        <span>{{ zip }}</span> <span>{{ city }}</span>
                      </span>
                      <div v-else class="contact__empty"></div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </transition>
        </div>
      </div>
      <button
        class="modal__button modal__button--submit button button--default"
        @click="clickHandler()"
      >
        <span>{{ buttonState }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import Loading from "@/partials/Loading";
import { parseCustomerName } from "@/helpers/entityParsers";
export default {
  name: "SearchPotentialCustomerModal",

  components: {
    Loading,
  },

  data() {
    return {
      formLoading: false,
      phoneKeywords: "",
      potentialCustomerContact: {},
      showSearch: true,
      customerExists: false,
      phoneKeywordsHasError: false,
      buttonState: this.$t(`searchPotentialCustomers.${"searchBtn"}`),
      parseCustomerName,
    };
  },

  watch: {
    phoneKeywords(value) {
      if (value) {
        this.phoneKeywordsHasError = false;
      } else {
        this.phoneKeywordsHasError = true;
      }
    },

    showSearch(state) {
      if (state) {
        this.buttonState = this.$t(`searchPotentialCustomers.${"searchBtn"}`);
      }
    },

    potentialCustomerContact(state) {
      if (state) {
        this.buttonState = this.$t(`searchPotentialCustomers.${"finishBtn"}`);
      } else {
        this.buttonState = this.$t(`searchPotentialCustomers.${"addCustomerBtn"}`);
      }
    },
  },

  computed: {
    street() {
      return this.potentialCustomerContact
        ? this.potentialCustomerContact.customer_addresses.length
          ? this.potentialCustomerContact.customer_addresses[0].street
          : null
        : null;
    },

    zip() {
      return this.potentialCustomerContact
        ? this.potentialCustomerContact.customer_addresses.length
          ? this.potentialCustomerContact.customer_addresses[0].zip
          : null
        : null;
    },

    city() {
      return this.potentialCustomerContact
        ? this.potentialCustomerContact.customer_addresses.length
          ? this.potentialCustomerContact.customer_addresses[0].city
          : null
        : null;
    },

    photo() {
      return this.potentialCustomerContact
        ? this.potentialCustomerContact.photo
          ? this.potentialCustomerContact.photo.thumbnail
          : null
        : null;
    },
  },

  methods: {

    addFamilyMember() {

    },

    clickHandler() {
      if (this.showSearch) {
        this.search();
      } else if (!this.showSearch && !this.customerExists) {
        this.openCustomers();
      } else {
        this.$store.commit("setShowSearchPotentialCustomerModal", false);
      }
    },

    openPotentialPolicy() {
      this.$store.commit("setShowSearchPotentialCustomerModal", false);
      this.$store.commit("setCustomerDetails", this.potentialCustomerContact);
      this.$store.commit("setShowAddPotentialPolicyModal", true);
    },

    async search() {
      if (this.phoneKeywords) {
        this.formLoading = true;
        this.showSearch = false;
        this.phoneKeywordsHasError = false;
        try {
          this.potentialCustomerContact = await this.$store.dispatch(
            "searchPotentialCustomer",
            this.phoneKeywords
          );
          if (!this.potentialCustomerContact) {
            this.customerExists = false;
          } else {
            this.customerExists = true;
          }
        } catch (err) {
          console.error(err);
        }
        this.formLoading = false;
      } else {
        this.phoneKeywordsHasError = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.add__content {
  padding-top: 100px !important;
  .form {
    &__label {
      color: var(--text-2);
      text-align: center;
      margin-bottom: 20px;
      font-size: var(--x-small);
      font-family: var(--muli-text-semi-bold);
    }

    &__field {
      margin-bottom: 40px;
    }
  }
}
.button {
  left: 50%;
  transform: translateX(-50%);
  bottom: 150px;
}

.contact {
  box-shadow: 0px 3px 6px var(--shadow-3);
  padding: 25px 10px 25px 80px;
  border-radius: var(--radius-1);
  position: relative;
  transition: all 0.3s ease;

  &--exists {
    cursor: pointer;

    &:hover {
      background: var(--bg-6);
      box-shadow: none;
    }
  }

  &__thumbnail {
    position: absolute;
    height: 46px;
    width: 46px;
    background: var(--bg-21);
    border-radius: 50%;
    top: 10px;
    left: 20px;
  }

  &__name {
    color: var(--text-9);
    margin-bottom: 20px;
  }

  &__address {
    margin-bottom: 6px;
  }

  &__address,
  &__city {
    color: var(--text-2);
    font-size: var(--x-small);
  }

  &__empty {
    height: 26px;
    width: 116px;
    background: var(--bg-21);
    border-radius: var(--radius-1);
    &--large {
      width: 143px;
    }
  }
}
</style>
