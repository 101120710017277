<template>
  <div class="row__dropdown" ref="dropdown">
    <div
      class="row__dropdown-item row__dropdown-item--edit icon__edit"
      @click="onEdit()"
      ref="dropdownEdit"
    >
      {{ $t(`buttons.${"editBtn"}`) }}
    </div>
    <div
      class="row__dropdown-item row__dropdown-item--separate icon__separate"
      @click="onSeparate()"
      ref="dropdownSeparate"
    >
      {{ $t(`buttons.${"separateBtn"}`) }}
    </div>    <div
      class="row__dropdown-item row__dropdown-item--delete icon__delete"
      @click="onDelete()"
      ref="dropdownDelete"
    >
      {{ $t(`buttons.${"saveBtn"}`) }}
    </div>
  </div>
</template>

<script>
export default {
  name: "MembersActionDropdown",

  props: {
  /* customerId: {
     required: true,
     type: String
   } */
   onEdit: {
      required: true,
      type: Function,
    },
    onDelete: {
      required: true,
      type: Function,
    },
    onSeparate: {
      required: true,
      type: Function,
    },
  },

  mounted() {
    document.addEventListener("click", this.checkIfClickedInside);
  },

  destroyed() {
    document.removeEventListener("click", this.checkIfClickedInside);
  },

  methods: {
    checkIfClickedInside(event) {
      const { target } = event;
      const { dropdown, dropdownEdit, dropdownDelete, dropdownSeparate } = this.$refs;
      if (
        target === dropdown.parentElement ||
        target === dropdownEdit ||
        target === dropdownDelete || dropdownSeparate
      ) {
        this.$emit("toggleDropdown", true);
      } else {
        this.$emit("toggleDropdown", false);
      }
    },
  },
};
</script>

<style>
.table .row__dropdown-item--separate {
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
}
.icon__separate {
  background:url("/icons/default/separate_icon.svg") no-repeat center;

}
</style>
